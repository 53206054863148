// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-humantrak-js": () => import("./../src/pages/work/humantrak.js" /* webpackChunkName: "component---src-pages-work-humantrak-js" */),
  "component---src-pages-work-ivebi-js": () => import("./../src/pages/work/ivebi.js" /* webpackChunkName: "component---src-pages-work-ivebi-js" */),
  "component---src-pages-work-projectlex-js": () => import("./../src/pages/work/projectlex.js" /* webpackChunkName: "component---src-pages-work-projectlex-js" */),
  "component---src-pages-work-santostour-js": () => import("./../src/pages/work/santostour.js" /* webpackChunkName: "component---src-pages-work-santostour-js" */),
  "component---src-pages-zown-privacypolicy-js": () => import("./../src/pages/zown/privacypolicy.js" /* webpackChunkName: "component---src-pages-zown-privacypolicy-js" */),
  "component---src-pages-zown-termsconditions-js": () => import("./../src/pages/zown/termsconditions.js" /* webpackChunkName: "component---src-pages-zown-termsconditions-js" */)
}

