import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

const HelmetComponent = ({ options, title }) => {
  const data = useStaticQuery(graphql`
    query helmetQuery {
      site {
        siteMetadata {
          title
          author
          imageUrl
          description
          keywords
        }
      }
    }
  `);

  return (
    <Helmet>
      {options && options.noIndex === true ? <meta name="robots" content="noindex" /> : null}
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
      <meta name="description" content={data.site.siteMetadata.description} />
      <meta name="keywords" content={data.site.siteMetadata.keywords} />
      <title>{title || data.site.siteMetadata.title}</title>
      <html lang="en" />
      <meta name="image" property="og:image" content={data.site.siteMetadata.imageUrl} />
      {/* Google / Search Engine Meta Tags */}
      <meta itemprop="name" content={data.site.siteMetadata.author} />
      <meta itemprop="description" content={data.site.siteMetadata.description} />
      <meta itemprop="image" content={data.site.siteMetadata.imageUrl} />
    </Helmet>
  );
};

export default HelmetComponent;
