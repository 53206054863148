import React from 'react';
import Helmet from 'components/helmet';
import Header from 'components/header';
import fonts from 'fonts/fonts.css';

export default ({ children, pageContext }) => {
  const isWorkPage = pageContext.layout === 'work';

  if (pageContext.layout === 'zown') {
    return (
      <>
        <Helmet options={{ noIndex: true }} />
        <div style={{ backgroundColor: '#ffffff' }}>{children}</div>
      </>
    );
  }

  return (
    <>
      <link rel="preload" as="style" href={fonts} />
      <Helmet />
      <div style={{ backgroundColor: isWorkPage ? '#f4f4f4' : '#ffeb3b' }}>
        <Header largeLogo={!isWorkPage} />
        {children}
      </div>
    </>
  );
};
