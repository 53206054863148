import React from 'react';
import { FaEnvelope } from 'react-icons/fa';

import 'components/style.scss';
import './header.scss';

import big62_stacked from 'images/big62_stacked.svg';
const Header = ({ largeLogo }) => (
  <section className="big62-section" style={{ paddingTop: '2.5rem' }}>
    <div className="big62-container" style={{ position: 'relative' }}>
      <a href="/" title="Home">
        <img className={largeLogo ? 'large-logo' : 'small-logo'} src={big62_stacked} alt="logo" />
      </a>
      <a
        className="borderless is-hidden-tablet"
        style={{ zIndex: 1 }}
        href="mailto:hello@big62.com"
        style={{ position: 'absolute', top: '0', right: '0' }}
      >
        <FaEnvelope size="2rem" color="black" />
      </a>
      <a
        className="inverted-button is-small borderless is-hidden-mobile"
        style={{ zIndex: 1 }}
        href="mailto:hello@big62.com"
        style={{ position: 'absolute', top: '0', right: '0' }}
      >
        <p className="is-get-in-touch-text" style={{ transform: 'translateY(5px)' }}>
          Get in touch
        </p>
      </a>
    </div>
  </section>
);

export default Header;
